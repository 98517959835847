/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import React from "react";
import { MDXProvider } from "@mdx-js/react"


import "typeface-cormorant-garamond"
import "typeface-roboto"

function Layout({ children }) {


  return (
    <div className="flex flex-col min-h-screen">    
      <main className="flex  flex-col flex-1 mx-auto w-full">
      <MDXProvider
        components={{
          p: props => <p className="mb-4" {...props}/>,
          h1: props => <h1 className="text-5xl mb-3" {...props}/>,
          h2: props => <h2 className="text-4xl mb-2" {...props}/>,
          h3: props => <p className="text-2xl md:text-3xl mt-16" {...props}/>,
        }}
      >
        {children}
      </MDXProvider>
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
